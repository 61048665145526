<template>
  <v-card
      v-if="this.$store.state.scan.qr_event_data.b"
      dark
      class="mx-auto text-center  my-12"
      :max-width="getSettings.size.form_width"
  >

    <v-img
        src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
        height="150px"
    >
    </v-img>

    <v-card-title class="text-center" style="display:block">
      {{ $store.state.scan.qr_event_data.show }} [{{ $store.state.scan.qr_event_data.event }}]
    </v-card-title>

    <v-card-subtitle center>
      {{ $store.state.scan.qr_event_data.date }}
    </v-card-subtitle>


    <v-card-actions>

      <v-spacer></v-spacer>


      <v-btn
          light
          block
          color="amber lighten-2"
          to="/begin"
      >
        <v-icon>mdi-bookmark-outline</v-icon>
        Выбрать другой
      </v-btn>




    </v-card-actions>

  </v-card>

  <v-card
      v-else
      dark
      class="mx-auto text-center my-12"
      :max-width="getSettings.size.form_width"
  >

    <v-img
        src="https://picsum.photos/500/300?image=1"
        height="150px"
    >
    </v-img>

    <v-card-title
        class="text-center"
        style="display:block">
      Спектакль не загружен
    </v-card-title>

    <v-card-subtitle center>
      Статус [начало работы]<br>
    </v-card-subtitle>




    <v-card-actions>

      <v-btn
          light
          color="amber lighten-4"
          to="/begin"
      >
        <v-icon>mdi-view-list</v-icon>
        Выбрать
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
          light
          color="amber lighten-2"
          to="/begin"
      >
        <v-icon>mdi-auto-fix</v-icon>
        Определить
      </v-btn>






    </v-card-actions>

  </v-card>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Active",

  data: () => ({
    loaded: true
  }),

  computed:{
    ...mapGetters([
      "getSettings",
    ]),
  }
}
</script>

<style scoped>

</style>
